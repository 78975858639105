import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import CartTable from './cartTable';
import publicApi from '../apis/publicApi';
import kiosk from '../kiosk.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';


export default function PreorderSummary(props) {

    const { search } = useLocation();

    const [preorder, setPreorder] = useState();

    const [shop, setShop] = useState();

    useEffect(() => {
        (async () => {
            const result = await publicApi.get(`/preorder/${props.match.params.preorderId}`);
            setPreorder(result.data);
        })();
    }, [props.match.params.preorderId]);

    useEffect(_ => {
        if (!preorder || !preorder.shopId) return;
        (async () => {
            const result = await publicApi.get(`/shops/${preorder.shopId}`);
            setShop(result.data);
        })();
    }, [preorder])

    return <>
         <Navbar fixed="top" bg="primary" variant="dark" className="p-0 m-0">
            <Container fluid>
                {shop && <Link to={`/shops/${shop.shopId}/menu${search}`}>
                    <FontAwesomeIcon className="text-light" icon="home"/>
                </Link>}
                <Navbar.Brand className="fw-bold">
                   GreenSTOP
                </Navbar.Brand>
                <div></div>
            </Container>
        </Navbar>
        <Container className="mt-5">
            <h3 className="text-center mt-2 mb-0">Preorder - <span className='fw-bold'>{ shop?.name }</span></h3>
            { preorder &&
                <>
                    <h6>DLN: {preorder.dlnMasked}</h6>
                    <h6>Email: {preorder.email}</h6>
                    <CartTable cart={{...preorder, total: preorder?.items.reduce((total, i) => +(total + i.price*i.quantity).toFixed(2), 0)}}/>
                    <span className='fw-bold'>
                        Pickup Available Now at GreenSTOP Kiosk <br/>
                        { shop && <span className='fst-italic'>
                            { shop.address } { shop.city }, { shop.state } {shop.zip}
                        </span>}
                    </span>
                    <Row className="mt-2">
                        <Col xs="5" md="4" lg="4">
                            <Image style={{maxHeight: '500px'}} fluid src={kiosk}/>
                        </Col>
                        <Col xs="7" md="8" lg="8">
                            <Card>
                                <Card.Header>Terminal(s):</Card.Header>
                                    <ListGroup style={{maxHeight: '250px', overflow: 'scroll'}}>
                                        { Object.keys(preorder.availableTerminals)
                                            .filter(t => preorder.availableTerminals[t])
                                            .map(t => <ListGroup.Item className="border-0 py-0" key={t}>{t.split('-t')[1] ? `Side ${t.split('-t')[1]}` : t }</ListGroup.Item>)
                                        }
                                    </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    </>
}
