import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router';
import ShopInfo from './shopInfo';
import GreenstopLogoFull from '../GreenStopLogoFull.png'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/esm/Container';

export default function Shops({ shops, noRedirect }) {

    const [ready, setReady] = useState(false);
    const [coords, setCoords] = useState({lat: 0, lng: 0});
    useEffect(_ => {
        if (noRedirect) return setReady(true);
        navigator.geolocation.getCurrentPosition(position => {
            if (position.coords.latitude || position.coords.longitude) setCoords({ lat: position.coords.latitude, lng: position.coords.longitude })
            setReady(true);
        }, _ => setReady(true))
    }, [noRedirect])

    if (!shops || !ready) return 'loading...';

    if (!noRedirect) {
        let closeShop = shops.find(shop => shop.location && (((shop.location.lat - coords.lat)**2 + (shop.location.lng-coords.lng)**2) < 900));
        if (closeShop) return <div><Redirect to={`/shops/${closeShop.shopId}/menu`}/></div>
    }

    let shopCards = shops.map(shop =>
        <Col key={shop._id} md={6} xl={4} className="mt-3">
            <Card className='border-0'>
                <Card.Body className="p-0">
                    <div style={{position: 'relative', minHeight: '100px'}}>
                    <div style={{position: 'absolute', left: 0, right: 0, bottom: 0, padding: '50px 10px 7px 10px', background: 'linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, .9)), linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, .9))'}}>
                        <ShopInfo shop={shop} textColor="white" badgeText=""/>
                    </div>
                    <Card.Img src={shop.banner} className="rounded-0" style={{maxHeight: '180px', objectFit: 'cover'}}/>

                    </div>
                </Card.Body>
                    <Button href={`#/shops/${shop.shopId}/menu`} className="rounded-0" variant="primary">
                        View Menu
                    </Button>
            </Card>
        </Col>
    )
    return <>
        <Navbar bg='light' className='py-0'>
            <Container fluid className='justify-content-center d-flex'>
                <Navbar.Brand className='py-0'>
                    <Image style={{height: '70px'}} src={GreenstopLogoFull}/>
                </Navbar.Brand>

            </Container>
        </Navbar>
        <Row className="px-0 mx-0">
            {shopCards}
        </Row>
    </>
}
