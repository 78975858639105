import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Badge from "react-bootstrap/Badge";
import { useHistory } from 'react-router';
import { useEffect } from "react";
import { sanitize } from 'dompurify';
import AddToCartButton from "./addToCartButton";
import Button from "react-bootstrap/Button";

const cannabisColorMap = {
    sativa: '#FF722C',
    'sativa-hybrid': '#EF6F6C',
    indica: '#922CFF',
    'indica-hybrid': '#828CFF',
    hybrid: '#02E81A',
    cbd: '#FFD700'
}

export default function ProductInfo({product, back, cart, dispatch}) {
    const history = useHistory();
    useEffect(_ => {
        let unblock = history.block();
        let backListener = history.listen(_ => back());
        return _ => {
            backListener();
            unblock();
        }
    }, [history, back])
    
    return <div>
        <div onTouchMove={back} onClick={e => { e.stopPropagation(); back()}} style={{position: 'fixed', zIndex: 998, backgroundColor: 'grey', opacity: .5, height: '100vh', width: '100vw', top: 0}}></div>
        <Card className="border-bottom-0 mx-1 rounded-0 rounded-top" style={{position: 'fixed', zIndex: 999, opacity: 1, bottom: 0, backgroundColor: 'white', maxHeight: '85%', left: "5px", right: "5px"}}>
            <Card.Header className="bg-white border-0 pb-1 pe-1 d-flex justify-content-between">
                <span className="mt-1 ml-2 fw-bold text-muted">{product.name}</span>
                <Button style={{maxHeight: '30px', marginRight: '3px'}} onClick={back} size="sm" variant='outline-secondary'>X</Button>
            </Card.Header>
            <Card.Body className="pt-0" style={{overflow: 'scroll'}}>
                <Row>
                    <Col className="justify-content-center d-flex">
                        { product.images ?
                            <Carousel style={{height: '350px'}}>
                                { (product.images || [product.img]).map((imgUrl, i) =>
                                    <Carousel.Item key={i} >
                                        <Image src={imgUrl} fluid style={{maxHeight: '350px'}} alt="Img"/>
                                    </Carousel.Item>
                                )}
                            </Carousel> :
                            <Image src={product.img} fluid style={{maxHeight: '350px'}}/>
                        }
                    </Col>
                    <Col md="4" lg="5" xl="6" className="mt-2">
                        <Badge className="me-1 mb-1">{product.categoryName}</Badge>
                        <Badge className="me-1 mb-1 text-uppercase" bg="" style={{backgroundColor: cannabisColorMap[product.cannabisType]}}>{product.cannabisType}</Badge>
                        { product.featured && <Badge bg="danger" className="me-1 mb-1">Featured</Badge> }
                        { product.testing && Object.keys(product.testing).filter(k => product.testing[k]).map((key, idx) => (
                            product.testing[key].pct ?
                                <Badge className="me-1 mb-1" bg="secondary" key={idx}>{product.testing[key].pct}% <span className="text-uppercase">{key}</span></Badge> :
                                <Badge className="me-1 mb-1" bg="secondary" key={idx}>{product.testing[key].mg} mg <span className="text-uppercase">{key}</span></Badge>
                        ))
                        }
                    </Col>
                    <Col md="12">
                        <p className="m-1" dangerouslySetInnerHTML={{__html: sanitize(product.description)}}></p>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col xs="6" className="h5 text-primary">${product.price}</Col>
                    <Col xs="6">
                        <div className="float-end">
                            <AddToCartButton item={product} cart={cart} dispatch={dispatch}/>
                        </div>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    </div>
        // {/* <div style={{position: "fixed", bottom: '10px', right: '20px', background: 'white'}}>
        //     <AddToCartButton item={product} cart={cart} dispatch={dispatch}/>
        // </div> */}
}
