import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

export default function AddToCartButton({ item, cart, dispatch }) {
    let itemInCart = cart.items[item.productId];
    if (itemInCart) {
        let hasStock = itemInCart.quantity < item.stock;
        let productId = item.productId;
        return (
            <ButtonGroup size='sm' style={{marginTop: '-1px'}}>
                <Button className="rounded-0" onClick={_ => dispatch({ type: itemInCart.quantity > 1 ? 'minus' : 'delete', productId})}>
                    <FontAwesomeIcon size='xs' icon="minus"/>
                </Button>
                <Button disabled className="font-weight-bold px-2">{itemInCart.quantity}</Button>
                <Button className="rounded-0" disabled={!hasStock} onClick={_ => hasStock && dispatch({ type: 'plus', productId })}>
                    <FontAwesomeIcon size='xs' icon="plus" />
                </Button>
            </ButtonGroup>
        )
    } else {
        const addToCart = _ => dispatch({
            type: 'put',
            item: {...item, quantity: 1}
        })
        return <Button size="sm" className="px-2 font-weight-bold rounded-0" style={{borderRadius: '0px', marginTop: '-1px'}} onClick={addToCart}>
            <FontAwesomeIcon size='xs' icon="plus" />
        </Button>
    }
}
