import './App.scss';
import Home from './components/home';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart, faCartPlus, faUser, faPlus, faMinus, faArrowLeft, faBars, faHome, faArrowCircleLeft, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faShoppingCart, faCartPlus, faUser, faPlus, faMinus, faArrowLeft, faBars, faHome, faArrowCircleLeft, faTimesCircle, faTrashAlt)

toast.configure({
  autoClose: 4000,
  position: 'bottom-right'
})

function App() {
  return <Home/>
}

export default App;
