import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import GreenstopLogoFull from '../GreenStopLogoFull.png'
import { useLocation } from 'react-router-dom';

export default function Is21Modal() {

    const { search } = useLocation();
    const [is21, setIs21] = useState(false)
    useEffect(_ => {
        setIs21(localStorage.getItem('greenstopIs21'));
    }, [])

    return !search?.includes('embed=true') ? <Modal className='is21' show={!is21} centered style={{'background': 'none', opacicy: 0}} size='md'>
        <div style={{width: '100%', height: 0, paddingTop: '100%'}}>
            <div style={{marginTop: '-82%', position: 'relative'}} className='mx-4'>
                <h4 className='text-center text-success fw-bold' style={{display: 'block'}}>You must be 21</h4>
                <h4 className='text-center text-success fw-bold' style={{display: 'block'}}>or over to enter this site</h4>
                <Button variant="success rounded-0 block" className="pt-2 my-2" style={{width: '100%'}} onClick={_ => { localStorage.setItem('greenstopIs21', true); setIs21(true) }}>
                    <h4 className='fw-bold'>I'm 21+</h4>
                </Button>
                <Button variant="light rounded-0 block" className="pt-2 mt-0" style={{width: '100%'}}>
                    <h4>I'm not 21 yet</h4>
                </Button>
                <div className='px-4 pt-2'>
                    <Image className='px-5' style={{display: 'block'}} src={GreenstopLogoFull} fluid/>
                </div>
            </div>
        </div>
    </Modal> : null
}
