import { CartContext } from '../contexts/cartContext';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import publicApi from '../apis/publicApi';
import CartTable from './cartTable';
import ShopInfo from './shopInfo';

const STATES = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

export default function Checkout({ shop }) {
    
    const [cart, dispatch] = useContext(CartContext);
    const [dln, setDln] = useState();
    const [driversLicenseState, setDriversLicenseState] = useState();
    const [email, setEmail] = useState();
    const [disableButtons, setDisableButtons] = useState(false);
    const history = useHistory();
    const { search } = useLocation();

    const postPreorder = (driversLicenseNumber, email, items, singleVendTerminals) => {
        if (!items[0]) return; // TODO: toast.error
        setDisableButtons(true);

        let multiSideVend = shop.allowMultiSideVend && !singleVendTerminals.length;
        let availableTerminals = multiSideVend ?
            items.map(i => (i.locationInfo || [])
                .filter(l => l.stock >= i.quantity)
                .map(l => l.location)
            ).flat().reduce((out, t) => ({...out, [t]: true}) , {}) :
            singleVendTerminals;

        publicApi.post('/preorder', {
            items,
            driversLicenseNumber,
            driversLicenseState,
            email,
            availableTerminals,
            multiSideVend,
            shopId: items[0].shopId
        }).then(resp => {
            //show order overview and instructions
            history.push(`/preorder/${resp.data._id}${search}`)
            dispatch({type: 'clear'});
        }).catch(err => {
            setDisableButtons(false);
            console.log('heyll', err.response)
            toast.error(err?.response?.data ? err.response.data : 'An error occurred placing your preorder');
        })
    }

    return <>
        <Navbar expand={false} fixed="top" bg="dark" variant="dark" className="p-0 m-0" style={{height: '60px'}}>
            <Container fluid>
                <Button variant='dark' className='rounded-circle' onClick={_ => history.goBack()}>
                    <FontAwesomeIcon icon='arrow-circle-left' size='xl'/>
                </Button>
                <Navbar.Brand>
                    <h3 className="text-center">Checkout</h3>
                </Navbar.Brand>
            </Container>
        </Navbar>
        <Container className="mt-5 pt-4">
            <ShopInfo shop={shop}/>
            <hr/>
            <Form.Group controlId="dln">
                <Form.Label className="mb-1 mt-2 ps-1 fw-bold text-secondary">Drivers License or ID Number</Form.Label>
                <Form.Control className="mb-2" onChange={e => setDln(e.target.value)} type="text" placeholder="A1234567" />
            </Form.Group>
            <Form.Group controlId="state">
                <Form.Label className="mb-1 mt-2 ps-1 fw-bold text-secondary">State</Form.Label>
                <Form.Select className="mb-2" onChange={e => setDriversLicenseState(e.target.value)}>
                    <option value={''}>---</option>
                    { STATES.map(state => <option value={state} key={state}>{state}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label className="mb-1 mt-2 ps-1 fw-bold text-secondary">Email</Form.Label>
                <Form.Control className="mb-2" onChange={e => setEmail(e.target.value)} type="text" />
            </Form.Group>
            <hr className='mt-5'/>
            <div className="ps-3">
                <CartTable cart={cart}/>
                <p>Taxes: <span className="text-muted">Calculated at Payment</span></p>
            </div>
        </Container>
        <Navbar fixed='bottom' bg='light'>
            <Container fluid className='d-flex flex-row-reverse'>
                <Button style={{ boxShadow: '1px 3px 3px darkslategrey' }} disabled={!dln || !driversLicenseState || !email || disableButtons} onClick={_ => postPreorder(dln, email, Object.values(cart.items), cart.availableTerminals)}>Place Preorder</Button>
            </Container>
        </Navbar>
    </>
}
