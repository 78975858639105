import { createContext, useReducer } from "react"

const initialState = { items: {}, availableTerminals: { all: true }, total: 0, numItems: 0 };

function reducer(state, action) {
    if (action.type === 'clear') return initialState;

    let productId = action.productId || action.item.productId;
    let currItem = state.items[productId];

    let item = ({
        put: _ => action.item,
        plus: _ => ({ ...currItem, quantity: currItem.quantity + 1 }),
        minus: _ => ({ ...currItem, quantity: currItem.quantity - 1 }),
        delete: _ => undefined
    }[action.type])();

    let cart = { 
        items: {
            ...state.items,
            [productId]: item
        }
    };

    if (action.type === 'delete') delete cart.items[productId];

    let cartItemsArr = Object.values(cart.items);

    cart.total = +cartItemsArr.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    cart.numItems = cartItemsArr.reduce((numItems, item) => numItems + item.quantity, 0);
    cart.shopId = cart.numItems ? cartItemsArr[0].shopId : null;
    cart.availableTerminals = cart.numItems ? cartItemsArr.reduce((availableTerminalMap, item) => {
        let itemTerminals = item.locationInfo.filter(l => l.stock >= item.quantity).map(l => l.terminalId);
        if (availableTerminalMap.all) return itemTerminals.reduce((out, t) => ({...out, [t]: true}), {})
        else return itemTerminals.reduce((out, t) => {
            if (availableTerminalMap[t]) return {...out, [t]: true};
            else return out;
        }, {})
    }, state.availableTerminals) : { all: true }

    return cart;
}

export const CartContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <CartContext.Provider value={[ state, dispatch ]}>
            { children }
        </CartContext.Provider>
    )
}