import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import stopsignLogo from '../stopsign-logo.png';

export default function ShopInfo({ shop, badgeText, textColor }) {
    return shop ? <>
        <Image className='float-start me-2' roundedCircle fluid src={shop.img || shop.logo || stopsignLogo} style={{maxHeight: '50px'}}/>
        <h1 className="text-start fw-bolder mb-0 ps-5" style={{color: textColor || "#535C53", marginTop: '-5px'}}>{ shop.name }</h1>
        <h6 className="text-start ms-5" style={{color: "#6c757d", marginBottom: '10px'}}>
            { shop.address } - { shop.city }, { shop.state } {shop.zip}
        </h6>
        <Badge className="mt-1 pb-1" style={{boxShadow: '0px 1px 2px darkgreen'}}>
            { typeof badgeText === 'string' ? badgeText : <>Pickup available at <span className='fw-bolder fst-italic'>GreenSTOP</span> &nbsp;vending machine</>}
        </Badge>
    </> : null
}