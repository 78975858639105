import { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import publicApi from '../apis/publicApi';
import { CartContext } from '../contexts/cartContext';
import stopsignLogo from '../stopsign-logo.png';
import ProductInfo from './productInfo';
import AddToCartButton from './addToCartButton';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainMenu from './mainMenu';
import ShopInfo from './shopInfo';
import FeaturedProducts from './featuredProducts';

const cannabisColorMap = {
    sativa: '#FF722C',
    'sativa-hybrid': '#EF6F6C',
    indica: '#922CFF',
    'indica-hybrid': '#828CFF',
    hybrid: '#02E81A',
    cbd: '#FFD700'
}

const compareItems = (a, b) => {
    if (a.featurePreorderRibbon) return b.featurePreorderRibbon ? 0 : -1;
    else if (b.featurePreorderRibbon) return 1;
}

export default function ShopMenu({ match, showCart, shop}) {
    const history = useHistory();
    const { search } = useLocation();
    const [menu, setMenu] = useState();
    const [cart, dispatch] = useContext(CartContext);
    const [selectedProduct, setSelectedProduct] = useState();
    const [showFeaturedPopup, setShowFeaturedPopup] = useState(false);
    const [bannerAdUrl, setBannerAdUrl] = useState();

    useEffect(_ => {
        publicApi.get(`/shops/${match.params.shopId}/menu`).then(resp => resp.data || []).then(menuItems => menuItems.filter(item => item.price).sort(compareItems)).then(setMenu)
        publicApi.get(`/shops/${match.params.shopId}/bannerAd`).then(resp => setBannerAdUrl(resp.data?.adImageUrl))
    }, [match.params.shopId])

    if (!menu) return null;

    console.log(cart.availableTerminals)

    const MenuRow = items => items.map(item =>
            <div key={item._id}  style={{ display: 'inline-block', padding: '5px 10px 10px 10px', width: '170px' }} className="align-items-stretch">
                <Card style={{width: '100%', borderTopRightRadius: 0, boxShadow: (item.featurePreorderRibbon) ? '0px 1px 4px 2px rgba(34,145,34, .6)' : '-1px -2px 1px 1px rgba(235,235,235, .6)'}} className={'border-0 border-top border-light'}>
                    <Card.Header className="d-flex justify-content-between p-0 border-0">
                        <span className='p-1 text-primary fw-bold' >${item.price}</span>
                        {showCart && <AddToCartButton item={item} cart={cart} dispatch={dispatch}/>}
                    </Card.Header>
                    <Card.Body onClick={_ => !selectedProduct && setSelectedProduct(item)} className="d-flex align-items-center p-0 border-0">
                        <div style={{position: 'relative'}}>
                            <Card.Img style={{borderRadius: 0, height: '170px'}} src={ item.imgSmall || item.img || stopsignLogo}/>
                            {(item.featurePreorderRibbon) && <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, background: 'rgba(17,160,60,0.7)', color: 'white', fontWeight: 'bold', textAlign: 'center', boxShadow: '0px 2px 1px rgba(0,80,0,0.6)', textShadow: '0 2px 2px rgba(40,40,40,0.4)'}}>FEATURED</div>}
                        </div>
                    </Card.Body>
                    <Card.Footer className="px-1 bg-white " style={{border: 'none', fontSize: '.6em', whiteSpace: 'normal',  overflowWrap: 'break-all', textOverflow: 'wrap', background: cannabisColorMap[item.cannabisType]}} onClick={_ => setSelectedProduct(item)}>
                        <span className={`fw-bold ${(item.featurePreorderRibbon) ? 'text-success' : 'text-muted'}`}>{item.name}</span>
                    </Card.Footer>
                </Card>
            </div>
        )

    // let mainMenuCards = MenuRow(menu);

    let availableMenu = shop?.allowMultiSideVend ? menu : menu.filter(item => item.locations.some(location => cart.availableTerminals['all'] || cart.availableTerminals[location]))

    let categoryMenus = Object.values(availableMenu.reduce((categoryMap, item) => {
        categoryMap[item.category] = (categoryMap[item.category] || []).concat(item);
        return categoryMap;
    }, {}))

    let categoryRows = categoryMenus.map(MenuRow);

    const navegateToCheckout = _ => history.push(`/shops/${match.params.shopId}/checkout${search}`);
    const checkoutButtonPressed = _ => {
        let ribbonFeatureIds = availableMenu.map(p => p.featurePreorderRibbon).filter(fId => fId);
        let popupFeatureIds = availableMenu.map(p => p.featurePreorderPopup).filter(fId => fId);
        let allFeatureIds = ribbonFeatureIds.concat(popupFeatureIds);
        if (allFeatureIds.length) publicApi.post(`/shops/${match.params.shopId}/viewProductFeatures`, allFeatureIds).then(_ => console.log('submitted feature views'))
        if (popupFeatureIds.length) setShowFeaturedPopup(true);
        else navegateToCheckout()
    }

    return <div style={{overflowX: 'hidden'}}>
        { shop && <MainMenu shop={shop} onCartOpen={_ => setSelectedProduct(null)}/> }
        { selectedProduct && <ProductInfo back={_ => setSelectedProduct(null)} product={selectedProduct} cart={cart} dispatch={dispatch}/> }
        { showFeaturedPopup && <FeaturedProducts items={availableMenu.filter(p => p.featurePreorderPopup)} cart={cart} dispatch={dispatch} navegateToCheckout={navegateToCheckout} close={setShowFeaturedPopup}/>}
        <Container fluid className='pt-2 px-0 pb-5' style={{ backgroundColor: 'white'}}>
            <Row className='pt-1 px-3'>
                <Col lg='6' className='mb-2'>
                    <ShopInfo shop={shop}/>
                </Col>
                <Col lg='6'>
                    {bannerAdUrl && <Image src={bannerAdUrl} style={{height: '100px', width: '100%'}}/> }
                </Col>
            </Row>
            {/* <div style={{ display: 'block', overflow: 'auto', whiteSpace: 'nowrap' }}>
                {mainMenuCards}
            </div> */}

            { categoryRows.map((row, idx) =>
                !!row?.length && <Card key={idx} className="mt-2 border-0">
                    <h2 className="pt-2 px-3 mb-0 text-secondary fw-bold">{categoryMenus[idx][0]?.categoryName}</h2>
                    <div className="scroll-shadows ps-2" style={{ display: 'block', overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                        {row}
                    </div>
                </Card>)
            }
            { cart.shopId && (match.params.shopId !== cart.shopId) &&
                <>
                    <h3>You have an active cart for a different shop, would you like to clear your cart and start a new one?</h3>
                    <h4>
                        <Button variant="danger" onClick={_ => history.push(`/shops/${cart.shopId}/menu`)}>No</Button>
                        <Button onClick={_ => dispatch({type: 'clear'})}>Yes</Button>
                    </h4>
                </>
            }
        </Container>
        { cart.numItems && !selectedProduct ?
            <Navbar bg='light' fixed='bottom' className='py-1' style={{ boxShadow: '-2px 0px 3px grey' }}>
                <Container fluid className='d-flex justify-content-between'>
                    <div>
                        <small className=''>Subtotal</small>
                        <h5 className="fw-bold my-0">
                            ${parseFloat(cart.total).toFixed(2)}
                        </h5>
                    </div>
                    <Button className='fw-bold' style={{ boxShadow: '1px 2px 3px darkslategrey' }}  onClick={checkoutButtonPressed}>
                        Checkout
                    </Button>
                </Container>
            </Navbar> : null
        } 
    </div>
}
