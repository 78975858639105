import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import CartTable from "./cartTable";
import { CartContext } from '../contexts/cartContext';
import { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Cart({ close }) {
    const [cart, dispatch] = useContext(CartContext);

    useEffect(_ => {
        if (!cart.numItems) close();
    }, [cart, close])

    return <>
        <div onTouchMove={close} onClick={e => { e.stopPropagation(); close()}} style={{position: 'fixed', zIndex: 998, backgroundColor: 'grey', opacity: .5, height: '100vh', width: '100vw', top: 0}}></div>
        <Card className="border-bottom-0 rounded-0 rounded-top" style={{position: 'fixed', zIndex: 1000, opacity: 1, bottom: 0, backgroundColor: 'white', height: '90%', left: '7px', right: '7px'}}>
            <Card.Header className="bg-white border-0 pb-1 pe-1 d-flex justify-content-between">
                <h1 className="mt-2 ml-2 fw-bold text-secondary">Cart</h1>
                <Button variant="outline-dark" className="border-0 m-1 p-0" style={{ height: '35px', width: '36px'}} onClick={close}>
                    <FontAwesomeIcon className="m-0" icon='times-circle' size="2x"/>
                </Button>
            </Card.Header>
            <Card.Body>
                <CartTable cartItems={cart} removeItem={productId => dispatch({type: 'delete', productId})}/>
            </Card.Body>
        </Card>
    </>

}
