import Modal from 'react-bootstrap/Modal';
import stopsignLogo from '../stopsign-logo.png';
import Card from 'react-bootstrap/Card';
import AddToCartButton from './addToCartButton';
import Button from 'react-bootstrap/esm/Button';
import { useEffect } from 'react';

export default function FeaturedProducts({items, cart, dispatch, navegateToCheckout, close}) {

    useEffect(_ => {
        if (!cart.numItems) close()
    }, [cart?.numItems, close])

    return <Modal show={true} centered style={{'background': 'none', opacicy: 0}} fluid={true} backdrop='static'>
        <Modal.Header>
            <h4 className='m-0'>We think you’ll love…</h4>
        </Modal.Header>
        <div className='d-flex flex-wrap justify-content-center'>
            { items.map(item => (
                <div key={item._id}  style={{ display: 'inline-block', padding: '5px 10px 10px 10px', width: '170px' }} className="align-items-stretch">
                    <Card style={{width: '100%', borderTopRightRadius: 0, boxShadow: '-1px -2px 1px 1px rgba(235,235,235, .6)'}} className={'border-0 border-top border-light'}>
                        <Card.Header className="d-flex justify-content-between p-0 border-0">
                            <span className='p-1 text-primary fw-bold' >${item.price}</span>
                            {<AddToCartButton item={item} cart={cart} dispatch={dispatch}/>}
                        </Card.Header>
                        <Card.Body className="d-flex align-items-center p-0 border-0">
                            <Card.Img style={{borderRadius: 0, height: '170px'}} src={ item.imgSmall || item.img || stopsignLogo}/>
                        </Card.Body>
                        <Card.Footer className="px-1 bg-white " style={{border: 'none', fontSize: '.6em', whiteSpace: 'normal',  overflowWrap: 'break-all', textOverflow: 'wrap'}}>
                            <span className='text-muted'>{item.name}</span>
                        </Card.Footer>
                    </Card>
                </div>
            ))}
        </div>
        <Button onClick={navegateToCheckout} className='rounded-0 rounded-bottom m-1'>No Thanks</Button>
    </Modal>
}
