import { useEffect, useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CartContext } from '../contexts/cartContext';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cart from './cart';

export default function MainMenu({ shop, onCartOpen }) {

    const [cart] = useContext(CartContext);
    const [cartOpen, setCartOpen] = useState(false);
    const { search } = useLocation();
    const headerRef = useRef();
    const navRef = useRef();
    const titleRef = useRef();

    const [scrolled, setScrolled] = useState(window.scrollY > 140);

    useEffect(() => {
        const onScroll = () => {
            let windowY = window.scrollY;
            let scrolled = windowY > 140;
            setScrolled(scrolled);

            let filter = `blur(${Math.floor(windowY < 200 ? windowY*windowY/800 : 50)}px)`;
            if (headerRef?.current) headerRef.current.style.filter = filter;
            if (navRef?.current) {
                navRef.current.style.filter = filter;
                navRef.current.style.visibility = scrolled ? null : 'hidden';
            }
            if (titleRef?.current) {
                titleRef.current.style.opacity = windowY > 175 ? 1 : 0
                titleRef.current.style.visibility = scrolled ? null : 'hidden';
            }
        }
        onScroll();
        // clean up code
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return <div style={{}}>
        <div style={{backgroundColor: '#111111', zIndex: -1, position: 'fixed', width: '100%'}}>
            <img ref={headerRef} alt='' src={shop.banner} style={{ objectFit: 'cover', objectPosition: 'top', height: '200px', width: '100%' }}/>
        </div>
        <div style={{height: '200px', zIndex: -1}}></div>
        <Navbar expand={false} fixed="top" bg={scrolled ? 'dark' : null} variant="dark" className="p-0 m-0" style={{height: '60px', width: '100%'}}>
            <div ref={navRef} style={{ position: 'absolute', height: '100%', width: "100%", backgroundImage:  `url("${shop.banner}")`, backgroundPositionY: 0, backgroundSize: '100%'}}></div>
            <Container fluid style={{zIndex: 999}}>
                { !search?.includes('embed=true') && <>
                    <Navbar.Toggle className="text-white mr-3 border-0" as="div">
                        <FontAwesomeIcon style={{transition: '0.4s', filter: 'drop-shadow(0px 0px 2px black)'}} size="lg" icon="bars"/>
                    </Navbar.Toggle>
                    <Navbar.Offcanvas id={`offcanvasNavbar-expand`}
                        aria-labelledby={`offcanvasNavbarLabel-expand`}
                        placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                <h3 className='fw-bolder text-secondary'>GreenSTOP</h3>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className='text-dark'>
                                Currently shopping at {shop.name}
                            </div>
                            <Image fluid src={shop.img || ''}/>
                            <Link to="/shops">Change Shop</Link>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </>}
                <Navbar.Brand className="fw-bold fs-3">
                    <span ref={titleRef} style={{ transition: "opacity 2s ease-in", visibility: 'hidden'}}>
                        <Image roundedCircle className='me-1' fluid src={shop?.img || ''} style={{maxHeight: '17px'}}/>
                        { !shop?.nameImg && shop?.name }
                    </span>
                </Navbar.Brand>
                <div className="text-white mr-3" style={{position: 'relative'}} onClick={_ => {
                    if (cart.numItems) {
                        setCartOpen(true);
                        onCartOpen && onCartOpen();
                    }
                }}>
                    <FontAwesomeIcon size="lg"  icon="shopping-cart" style={{filter: 'drop-shadow(0 0px 1px black)'}}/>
                    { !!cart.numItems && <div style={cartItemsBubbleStyle}>
                        {cart.numItems}
                    </div> }
                </div>
            </Container>
            { cartOpen && <Cart close={_ => setCartOpen(false)}/> }
        </Navbar>

    </div>

}

const cartItemsBubbleStyle = {
    transition: '0.4s',
    position: 'absolute',
    right: '-9px',
    top: '-8px',
    width: '17px',
    height: '17px',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '900',
    fontSize: '10px',
    lineHeight: '13px',
    padding: '1px 4px 4px 4px',
    background: 'red',
    borderRadius: '100%',
    color: 'lightgrey'
}
