import { useEffect, useState } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import publicApi from '../apis/publicApi';
import Shops from './shops';
import ShopMenu from './shopMenu'
import { CartProvider } from '../contexts/cartContext';
import Checkout from './checkout';
import PreorderSummary from './preorderSummary';
import Is21Modal from './is21Modal';

export default function Home() {
    const [shops, setShops] = useState([]);
    useEffect(_ => {
        publicApi.get('/shops').then(resp => resp.data).then(setShops)
    }, [])

    return <CartProvider>
            <Router>
                <Is21Modal/>
                <Route path='/' exact render={ props => <Shops {...props} shops={shops}/> } />
                <Route path='/shops' exact render={ props => <Shops {...props} shops={shops} noRedirect/> } />
                <Route path='/shops/:shopId/checkout' exact render={props => <Checkout {...props} shop={shops.find(shop => props.match.params.shopId === shop._id)}/>} />
                <Route path='/preorder/:preorderId' exact component={PreorderSummary}/>
                <Route path='/shops/:shopId/menu' exact render={(props) => (<ShopMenu {...props} shop={shops.find(shop => props.match.params.shopId === shop._id)} showCart={true}/>)} />
                <Route path='/shops/:shopId/menu/static' exact render={(props) => (<ShopMenu shop={shops.find(shop => props.match.params.shopId === shop._id)} {...props} showCart={false}/>)} />
            </Router>
    </CartProvider>
}
