import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function CartTable({ cart, removeItem }) {
    let cartItems = cart?.items || {};

    const cartItemRows = Object.values(cartItems).map(item => 
        <tr key={item._id}>
            <td className="fw-bold px-0" style={{lineHeight: '18px', fontSize: '16px'}}>{item.name}</td>
            <td className="text-muted pe-0">${item.price}<b> x</b>{item.quantity}</td>
            <td className="text-muted fw-bold">${item.quantity*item.price}</td>
            { removeItem &&
                <td>
                    <Button onClick={_ => removeItem(item.productId)} size="sm" variant="outline-danger" className="py-0 border-0">
                        <FontAwesomeIcon icon="trash-alt" size="lg"/>
                    </Button>
                </td>
            }
        </tr>
    )

    return (
        <Table className="mt-3">
            <tbody>
                {cartItemRows}
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td className="fw-bold">Total:</td>
                    <td className="text-muted fw-bold">${cart?.total}</td>
                    <td></td>
                </tr>
            </tfoot>
        </Table>
    )
}
